import { getString } from 'firebase/remote-config'
import { useMyDevice, useMyUser } from '..'
import { zimBackendRemoteConfig } from '../../firebase'
import { logSentryError } from '../../utils/logHelper'

export const useUserBasedFeatureFlag = (flagName: string, userFlagName: string) => {
  const deviceEmail = useMyDevice((state) => state.device?.userEmail)
  const warehouseId = useMyUser((state) => state.user?.warehouseId)

  if (!deviceEmail) return false
  if (!warehouseId) return false

  try {
    const foo = getString(zimBackendRemoteConfig, userFlagName)
    const isEnabledFor = foo.includes(deviceEmail)

    const flag = JSON.parse(getString(zimBackendRemoteConfig, flagName))
    const isEnabled = !!flag?.[warehouseId] || !!flag?.all

    return isEnabled || isEnabledFor
  } catch (error) {
    logSentryError(error)
    return false
  }
}
