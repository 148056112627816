interface AppErrorOptions {
  skipSentryLog?: boolean
}

export class AppError extends Error {
  skipSentryLog: boolean

  constructor(message: string, options: AppErrorOptions = {}) {
    super(message)
    this.name = this.constructor.name
    this.skipSentryLog = !!options.skipSentryLog
  }
}
