import { PurchaseOrderProduct } from '@quickcommerceltd/zipp'
import { PurchaseOrderCsvData } from '../_types'

export const generatePurchaseOrderCsvData = (
  purchaseOrderProducts: PurchaseOrderProduct[] | undefined
): PurchaseOrderCsvData[] => {
  return (
    purchaseOrderProducts?.map((product) => ({
      title: product.title,
      quantityPicked: product.quantityPicked,
      id: product.id,
      temperatureStorage: product.temperatureStorage,
      packSize: product.packSize,
      quantity: product.quantity,
      costTotal: product.costTotal,
      packs: product.packs,
      canExpire: product.canExpire,
      temperatureFulfillment: product.temperatureFulfillment,
      quantityInbounded: product.quantityInbounded,
      customerSLA: product.customerSLA,
      quantityPutaway: product.quantityPutaway,
      costBase: product.costBase,
      supplierSLA: product.supplierSLA,
    })) ?? []
  )
}
